/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable func-names */
/* eslint-disable react/prop-types */
import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './layout.css';
import { createTheme, Hidden, ThemeProvider } from '@mui/material';
// import ThinkZambiaAnnualReport from '../downloads/Think-Zambia-Annual-Report-2020.pdf';
import SmoothScroll from './SmoothScroll/SmoothScroll';
import '../styles/global.css';

const Layout = function ({ children, scrollingContainer }) {
  return (
    <SmoothScroll>
      <div ref={scrollingContainer}>
        <div>
          <div>
            {children}
          </div>
        </div>
      </div>
    </SmoothScroll>
  );
};

const desktopTheme = createTheme({
  palette: {
    primary: {
      main: '#111B2A',
    },
    secondary: {
      main: '#C7A567',
    },
  },
  typography: {
    subtitle1: {
      fontWeight: '100',
      fontSize: '1.55rem',
    },
    subtitle2: {
      fontWeight: '100',
      fontSize: '1.15rem',
    },
    body2: {
      fontWeight: '100',
    },
  },
});

const mobileTheme = createTheme({
  palette: {
    primary: {
      main: '#111B2A',
    },
    secondary: {
      main: '#C7A567',
    },
  },
  typography: {
    body2: {
      fontSize: '0.75rem',
      fontWeight: '100',
    },
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '3rem',
    },
    subtitle1: {
      fontWeight: '200',
      fontSize: '1.15rem',
    },
    subtitle2: {
      fontWeight: '250',
      fontSize: '0.9rem',
    },
  },
});

export default function Wrapped(props) {
  return (
    <>
      <Hidden only="xs">
        <ThemeProvider theme={desktopTheme}>
          <Layout {...props} />
        </ThemeProvider>
      </Hidden>
      <Hidden smUp>
        <ThemeProvider theme={mobileTheme}>
          <Layout {...props} />
        </ThemeProvider>
      </Hidden>
    </>
  );
}
