import React, { useState } from 'react';
import Layout from '../../components/layout';
import Query from './Query';

export default function Apply() {
  const [name, setName] = useState();
  const [reg, setReg] = useState();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState();
  const [position, setPosition] = useState();

  return (
    <Layout disableFooter>
      {step === 1 && (
      <Query
        query={"Bwanji, what's your name?"}
        value={name}
        setValue={setName}
        placeholder="Eg. Mapalo Mbewe"
        onSubmit={() => { setStep(2); }}
      />
      )}
      {step === 2 && (
        <Query
          query={`Thank you! what's your NRC number, ${name.split(' ')[0]}?`}
          value={reg}
          setValue={setReg}
          onSubmit={() => { setStep(3); }}
        />
      )}
      {step === 3 && (
        <Query
          query={"Great! what's your email?"}
          value={email}
          setValue={setEmail}
          placeholder="bantu@thiinkzambia.org"
          onSubmit={() => { setStep(4); }}
        />
      )}
      {step === 4 && (
        <Query
          query={"Ok! please tell me what position you're applying to?"}
          value={position}
          setValue={setPosition}
          placeholder="Bwana Operations"
          options={['Software Developer Intern', 'Product Manager']}
        />
      )}
    </Layout>
  );
}
