/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  Autocomplete,
  CardContent, Container, Fade, Hidden, TextField, Typography,
} from '@mui/material';
import React from 'react';

const Query = React.forwardRef(({
  query,
  value,
  setValue,
  placeholder,
  onSubmit,
  options,
}, ref) => (
  <div style={{
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#f1f1f1',
  }}
  >
    <Fade in timeout={1000}>
      <Container>
        <CardContent>
          <Hidden only="xs">
            <Typography align="center" variant="h2">
              {query}
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography align="center" variant="h4">
              {query}
            </Typography>
          </Hidden>
        </CardContent>
        <CardContent>
          {!options && (
          <TextField
            placeholder={placeholder}
            onChange={(event) => setValue(event.target.value)}
            value={value || ''}
            required
            fullWidth
            variant="standard"
            color="secondary"
            style={{
              paddingTop: '2rem',
              textAlign: 'center',
            }}
            inputProps={{
              style: {
                fontSize: '1.75rem',
                textAlign: 'center',
              },
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onSubmit) {
                onSubmit();
              }
            }}
          />
          )}
          {options && (
            <Autocomplete
              disablePortal
              id="select-value-autocomplete"
              options={options}
              fullWidth
              onChange={(event, v) => {
                setValue(v);
                if (onSubmit) {
                  onSubmit();
                }
              }}
              disableClearable
              autoHighlight
              autoSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  variant="standard"
                  color="secondary"
                  style={{
                    paddingTop: '2rem',
                    textAlign: 'center',
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      fontSize: 40,
                      textAlign: 'center',
                    },
                  }}
                />
              )}
            />
          )}
        </CardContent>
      </Container>
    </Fade>
  </div>
));

export default Query;
